import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { isLoggedIn } from './isAccessFunction';
import history from './appHistory';
import { Spinner } from 'altreidsds';
// Containers
import Full from './containers/Full/';
import Simple from './containers/Simple/';

// Views
const Login = React.lazy(() => import('./views/Login'));
const Clients = React.lazy(() => import('./views/Clients'));
const Client = React.lazy(() => import('./views/Client'));

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn()) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

const loading = () => {
  return <Spinner />;
};

export default (
  <ConnectedRouter history={history}>
    <div>
      <Switch>
        <Simple path='/login'>
          <Suspense fallback={loading()}>
            <Route path='/' name='Login Page' render={props => <Login {...props} />} />
          </Suspense>
        </Simple>
        <Full path='/'>
          <Suspense fallback={loading()}>
            <Switch>
              <PrivateRoute path='/clients' exact name='Clients' component={Clients} />
              <PrivateRoute path='/clients/:clientId' exact name='Client' component={Client} />
              <PrivateRoute path='/' exact name='Clients' component={Clients} />
            </Switch>
          </Suspense>
        </Full>
      </Switch>
    </div>
  </ConnectedRouter>
);
