import { mapKeys } from 'lodash';
import { commonLocalization } from './common.loc';

const Localize = require('localize');
require('decliner/dist/decliner.min');

const localize = new Localize(commonLocalization, undefined, '');

localize.addTranslations = translate => {
  mapKeys(translate, key => {
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate);
    }
  });
};

localize.throwOnMissingTranslation(false);
export const { translate: t } = localize;
export default localize;
