const prefix = '@currentUser/';

export const LOGIN = prefix + 'LOGIN';
export const LOGIN_FULFILLED = prefix + 'LOGIN_FULFILLED';
export const LOGIN_PENDING = prefix + 'LOGIN_PENDING';
export const LOGIN_REJECTED = prefix + 'LOGIN_REJECTED';

export const LOGOUT = prefix + 'LOGOUT';
export const LOGOUT_FULFILLED = prefix + 'LOGOUT_FULFILLED';
export const LOGOUT_PENDING = prefix + 'LOGOUT_PENDING';
export const LOGOUT_REJECTED = prefix + 'LOGOUT_REJECTED';

export const SET_CURRENT_USER = prefix + 'SET_CURRENT_USER';
export const DROP_CURRENT_USER = prefix + 'DROP_CURRENT_USER';
export const DROP_EDITABLE_USER = prefix + 'DROP_EDITABLE_USER';
export const SET_LANGUAGE = prefix + 'SET_LANGUAGE';

export const CHANGE_LANGUAGE = prefix + 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_FULFILLED = prefix + 'CHANGE_LANGUAGE_FULFILLED';
export const CHANGE_LANGUAGE_PENDING = prefix + 'CHANGE_LANGUAGE_PENDING';
export const CHANGE_LANGUAGE_REJECTED = prefix + 'CHANGE_LANGUAGE_REJECTED';

export const SAVE_EDITABLE_USER = prefix + 'SAVE_EDITABLE_USER';
export const SAVE_EDITABLE_USER_FULFILLED = prefix + 'SAVE_EDITABLE_USER_FULFILLED';
export const SAVE_EDITABLE_USER_PENDING = prefix + 'SAVE_EDITABLE_USER_PENDING';
export const SAVE_EDITABLE_USER_REJECTED = prefix + 'SAVE_EDITABLE_USER_REJECTED';

export const CHECK_TOKEN = prefix + 'CHECK_TOKEN';
export const CHECK_TOKEN_FULFILLED = prefix + 'CHECK_TOKEN_FULFILLED';
export const CHECK_TOKEN_PENDING = prefix + 'CHECK_TOKEN_PENDING';
export const CHECK_TOKEN_REJECTED = prefix + 'CHECK_TOKEN_REJECTED';

export const GET_INSTANCE_LANGUAGE = prefix + 'GET_INSTANCE_LANGUAGE';
export const GET_INSTANCE_LANGUAGE_FULFILLED = prefix + 'GET_INSTANCE_LANGUAGE_FULFILLED';
