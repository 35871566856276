import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Drawer } from 'altreidsds';
import { List } from 'altreidsds';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from 'altreidsds';

import AppIcon from '../../components/AppIcon';
import { connect } from 'react-redux';
import BotNavListItem from './SidebarItems/BotNavListItem';
import { Scrollbars } from 'react-custom-scrollbars';

const drawerWidth = 260;
const drawerPaperCloseWidth = 81;

class Sidebar extends Component {
  state = {
    editableBot: null,
    anchorEl: null,

    messages: [],
  };

  render() {
    const { classes, open /*, currentUser, currentProject*/ } = this.props;

    return [
      <Drawer
        data-test-id='Drawer'
        key='sidebar-drawer'
        variant='permanent'
        id='sidebar'
        classes={{
          paper: classNames(classes.drawerPaper, !open && classes.drawerPaperClose),
          paperAnchorDockedLef: classes.paperAnchorDockedLef,
        }}
        open={open}
      >
        <Grid
          container
          wrap='nowrap'
          id='Sidebar'
          spacing={8}
          direction='column'
          justify='space-between'
          alignItems='stretch'
          className={classes.container}
          data-test-id='Sidebar'
        >
          <Grid item>
            <div className={classes.drawerHeader} data-test-id='Sidebar.drawerHeader'>
              <Grid spacing={24} container wrap='nowrap' justify='flex-start' alignItems='center'>
                <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Link to='/' data-test-id='Sidebar.mainMenuLink'>
                    <AppIcon />
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Scrollbars
            renderThumbVertical={props => <div {...props} className='visualEditor-thumb-vertical' />}
            renderView={props => <div {...props} className='sidebar-scroll-flex' />}
          >
            <Grid container className={classes.navContainer} direction='column' wrap='nowrap' justify='space-between'>
              <Grid item />
              <Grid item>
                <List>
                  <BotNavListItem
                    disabled={classes.disabled}
                    language={this.props.language}
                    openEditModal={this.props.togglePopup}
                    listItemClassSplitter={classNames(
                      classes.listItemClassSplitter,
                      !open && classes.listItemClassSplitterText
                    )}
                    listItemClassSplitterText={classNames(
                      classes.listItemClassSplitterText,
                      !open && classes.listItemClassSplitterTextBlock
                    )}
                    textListItem={classNames(classes.textListItem, !open && classes.textListItemBlock)}
                    listItemClass={classNames(classes.listItemClass, !open && classes.listItemClassBlock)}
                    listIconClass={classNames(classes.listIcon, !open && classes.listIconBlock)}
                    textClass={classNames(classes.listText, !open && classes.listTextBlock)}
                  />
                </List>
              </Grid>
              <Grid item />
            </Grid>
          </Scrollbars>
        </Grid>
      </Drawer>,
    ];
  }
}

function mapStateToProps(state) {
  return {
    language: state.CurrentUserReducer.language,
    currentUser: state.CurrentUserReducer.currentUser,
  };
}

/*
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({
        ...BotProjectsActions,
        setCurrentProject
    }, dispatch)
});
*/

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

const styles = theme => ({
  hiddenLink: {
    position: 'absolute',
    top: -10000,
    left: -10000,
    display: 'block',
  },
  Popper: {
    marginLeft: 66,
  },
  popoverRoot: {
    minWidth: '220px',
    marginLeft: 50,
    boxShadow: '0 0 1px 1px rgba(17, 60, 92, 0.06), 0 6px 12px 0 rgba(12, 43, 66, 0.16);',
  },
  menuItemRootSplitter: {
    margin: '2px 0',
    padding: 0,
    height: 1,
    background: theme.palette.gray.lightest,
    '&:hover': {
      cursor: 'default',
      background: theme.palette.gray.lightest,
    },
  },
  menuItemRootName: {
    color: theme.palette.gray.main,
    textTransform: 'none',
    fontWeight: 'normal',
    padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit * 2}px`,
    '&:hover': {
      background: 'inherit',
      cursor: 'default',
    },
  },
  menuItemRoot: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: '0.875rem',
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
    },
  },
  menuListPadding: {
    paddingTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 2,
  },
  container: {
    height: '100%',
  },
  brand: {
    color: theme.palette.common.white,
    fontWeight: 500,
    fontSize: '1.5em',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.white,
      textDecoration: 'none',
    },
  },
  menuButton: {
    borderRadius: '2px',
  },
  shift: {},
  drawerPaper: {
    backgroundColor: theme.palette.gray.blackTwo,
    position: 'fixed',
    height: '100vh',
    width: drawerWidth,
    boxSizing: 'content-box',
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    width: `${drawerPaperCloseWidth}px`,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawerHeader: {
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2.5}px`,
  },
  appNavUser: {
    padding: `${theme.spacing.unit * 1.75}px ${theme.spacing.unit * 2.5}px`,
  },
  listItemClass: {
    padding: '0',
    width: '100%',

    '&.sidebarPerson': {
      width: 34,
      padding: '4px 0',
      margin: '0 auto',
      borderRadius: '50%',
      background: theme.palette.contrast.white,
      '& svg': {
        width: 20,
        fill: '#2A2722',
      },
      '&:hover': {
        '& svg': {
          fill: 'inherit',
        },
      },
      '&.active': {
        background: theme.palette.primary.main,
        '& svg': {
          fill: theme.palette.contrast.white,
        },
      },
    },

    '& span.counter': {
      display: 'block',
      width: 16,
      height: 16,
      position: 'absolute',
      top: 0,
      right: 3,
      backgroundColor: theme.palette.accent.danger,
      borderRadius: '50%',
    },
  },

  listItemClassSplitter: {
    padding: '0',
    width: '100%',
  },
  listItemClassSplitterText: {
    boxSizing: 'border-box',
    textAlign: 'center',

    padding: '1.5em 1.9em',
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    lineHeight: '1.71429',
    color: theme.palette.common.white,
    display: 'flex',
    fontWeight: 500,
    opacity: '1',

    '& div': {
      padding: 0,
    },
  },
  disabled: {
    cursor: 'no-drop !important',
  },

  listIcon: {
    color: theme.palette.common.white,
    marginRight: 0,
  },
  botIcon: {
    display: 'inline-block',
    minHeight: '34px',
    minWidth: '34px',
    background: 'url("/img/botIcon.png") center center no-repeat',
    backgroundSize: 'contain',
    marginBottom: '0',
    marginRight: '0',
  },
  textListItem: {
    lineHeight: '24px',
  },
  listText: {
    fontSize: theme.typography.fontSize,
    textDecoration: 'none',
    padding: '1.5em 1.9em',
    lineHeight: '1.71429',
    color: theme.palette.text.white,
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    fontWeight: 500,
    textAlign: 'left',
    position: 'relative',

    '&:hover': {
      color: theme.palette.contrast.lightest,
      textDecoration: 'none',

      '& svg': {
        fill: theme.palette.contrast.lightest,
      },
    },
    '&.active': {
      backgroundColor: 'rgb(83, 88, 92, 0.5)',
      '& svg': {
        fill: theme.palette.text.lightest,
      },
    },
    '& svg': {
      fill: theme.palette.text.white,
    },
  },
  listItemClassBlock: {},
  listIconBlock: {},
  botIconBlock: {
    marginBottom: '10px',
  },
  textListItemBlock: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '0',
    lineHeight: '1',
  },
  listItemClassSplitterTextBlock: {
    fontSize: '.75em',
    display: 'block',
    textAlign: 'center',
    padding: '1em 0',
    lineHeight: 1,
    width: drawerPaperCloseWidth,
    '& div': {
      padding: 0,
    },
    '&:hover': {
      background: 'inherit',
    },
  },
  listTextBlock: {
    fontSize: '.75em',
    display: 'block',
    textAlign: 'center',
    padding: `${theme.spacing.unit}px 0`,
    lineHeight: 1,
    width: '100%',
    '& svg': {
      marginBottom: theme.spacing.unit / 2,
      marginRight: 0,
    },
  },
  navContainer: {
    minHeight: '100%',
  },
  botNavContainer: {
    flex: '1 1 auto',
  },
  paperAnchorDockedLef: {
    borderRight: 0,
  },
});

export default withRouter(
  connect(mapStateToProps /*, mapDispatchToProps*/)(withStyles(styles, { withTheme: true })(Sidebar))
);
