import {
  CHANGE_LANGUAGE_FULFILLED,
  CHECK_TOKEN_FULFILLED,
  CHECK_TOKEN_REJECTED,
  DROP_CURRENT_USER,
  GET_INSTANCE_LANGUAGE_FULFILLED,
  LOGIN_FULFILLED,
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGOUT_FULFILLED,
  LOGOUT_REJECTED,
  SAVE_EDITABLE_USER_FULFILLED,
  SAVE_EDITABLE_USER_PENDING,
  SAVE_EDITABLE_USER_REJECTED,
  SET_CURRENT_USER,
} from '../../constants/currentUser.actions';
import localize from '../../localization';
import { getCurrentUserFromLocalStorage } from '../../pipes/functions';

const currentUser = getCurrentUserFromLocalStorage();

const InitialState = {
  language: 'ru',
  currentUser: currentUser,
  fetching: false,
  error: '',
  success: '',
};

export default function CurrentUserReducer(state = InitialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER: {
      localStorage.setItem('CURRENT_USER', JSON.stringify(action.currentUser));

      return {
        ...state,
        currentUser: action.currentUser,
        error: '',
      };
    }

    case DROP_CURRENT_USER: {
      return {
        ...state,
        currentUser: InitialState.currentUser,
        fetching: InitialState.fetching,
        error: InitialState.error,
        success: InitialState.success,
      };
    }

    case LOGIN_FULFILLED: {
      localStorage.setItem('CURRENT_USER', JSON.stringify(action.payload.data));

      return {
        ...state,
        currentUser: action.payload.data,
        error: '',
        fetching: false,
      };
    }

    case LOGIN_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case LOGIN_REJECTED: {
      return {
        ...state,
        error: Boolean(action.payload.config.headers.Authorization)
          ? localize.translate('Wrong login or password')
          : '',
        fetching: false,
      };
    }

    case CHECK_TOKEN_FULFILLED: {
      return {
        ...state,
        success: localize.translate('Your Email has been successfully confirmed.'),
      };
    }

    case CHECK_TOKEN_REJECTED: {
      return {
        ...state,
        // error: localize.translate("Incorrect token"),
      };
    }

    case LOGOUT_FULFILLED: {
      localStorage.removeItem('CURRENT_USER');
      return {
        ...InitialState,
      };
    }

    case LOGOUT_REJECTED: {
      console.error(action);
      return {
        ...state,
      };
    }

    case CHANGE_LANGUAGE_FULFILLED: {
      /*localStorage.setItem("USER_LANGUAGE", langBackMap[action.payload.data.language]);*/
      /*let language = langBackMap[action.payload.data.language];*/

      let currentUser = {
        ...state.currentUser,
        language: action.payload.data.language,
      };
      localStorage.setItem('CURRENT_USER', JSON.stringify(currentUser));

      return {
        ...state,
        currentUser: { ...currentUser },
        /*language: language,*/
        changeUserLanguagePending: false,
      };
    }

    case SAVE_EDITABLE_USER_FULFILLED: {
      localStorage.CURRENT_USER = JSON.stringify(action.payload.data);
      localStorage.setItem('USER_LANGUAGE', state.language);
      return {
        ...state,
        fetching: false,
        currentUser: action.payload.data,
        language: state.language,
      };
    }
    case SAVE_EDITABLE_USER_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }
    case SAVE_EDITABLE_USER_REJECTED: {
      return {
        ...state,
        fetching: false,
        error: !!action.payload.response.data.error
          ? [action.payload.response.data.error]
          : action.payload.response.data.errors,
      };
    }

    case GET_INSTANCE_LANGUAGE_FULFILLED: {
      return {
        ...state,
        language: action.payload.data.language?.toLowerCase(),
      };
    }

    default: {
      return state;
    }
  }
}
