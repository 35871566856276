import React from 'react';
import { CircularProgress } from 'altreidsds';
import classNames from 'classnames';
import { withStyles } from 'altreidsds';
import PropTypes from 'prop-types';

const styles = theme => ({
  progressOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: '100%',
    left: 0,
    right: 0,
    zIndex: 10001,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255, .8)',

    '& svg': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  left: {
    borderRadius: '16px 0 0 16px',
  },
  right: {
    borderRadius: '0 16px 16px 0',
  },
  both: {
    borderRadius: '16px',
  },
});

const Spinner = props => {
  const { className, classes, size, roundedBorder } = props;
  return (
    <div
      data-test-id='Spinner'
      className={classNames(
        className,
        classes.progressOverlay,
        roundedBorder === 'left' && classes.left,
        roundedBorder === 'right' && classes.right,
        roundedBorder === 'both' && classes.both
      )}
    >
      <CircularProgress size={size === 'small' ? 24 : 40} color='primary' />
    </div>
  );
};
Spinner.defaultProps = {
  className: '',
};
Spinner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Spinner);
