import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import CurrentUserReducer from './currentUser.reducer/';
import SnackbarsReducer from './snackbar.reducer';
import ClientsReducer from './clients.reducer';

const rootReducer = combineReducers({
  routing: routerReducer,
  CurrentUserReducer,
  SnackbarsReducer,
  ClientsReducer,
});

export default rootReducer;
