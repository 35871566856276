import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from 'altreidsds';

const styles = theme => ({
  icon: {
    width: '60px',
    height: '60px',
    minHeight: '0',
    minWidth: '0',
    color: theme.palette.common.white,
    textAlign: 'center',
    '& .fa': {
      verticalAlign: 'middle',
      lineHeight: '32px',
    },
  },
  iconLarge: {
    width: '110px',
    height: '110px',
    lineHeight: 1.4,
    fontSize: '1em',
    '& .fa': {
      lineHeight: '32px',
    },
  },
});

class AppIcon extends Component {
  render() {
    const { classes, className, size } = this.props;
    const iconClass = Boolean(size) ? classNames(classes.icon, size === 'large' && classes.iconLarge) : classes.icon;

    return (
      <svg
        data-test-id='AppIcon'
        className={classNames(className, iconClass)}
        viewBox='0 0 300 300'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M210.4 254.825L163.6 281.125C154.8 286.225 143.9 286.225 135 281.125L88 254.225L42.8 228.325C33.2 223.725 27 214.025 27 203.325L27.5 96.525C27.6 86.625 33 77.425 41.7 72.625L88.9 46.125L135.8 19.825C144.6 14.725 155.5 14.725 164.4 19.825L211.4 46.725L256.6 72.525C266.2 77.125 272.4 86.825 272.4 97.525L271.9 204.325H271.8C271.7 214.225 266.3 223.325 257.6 228.225L210.4 254.825Z'
          fill='#FF5C3D'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M208 121.725L165.7 49.825C164.1 47.025 161.7 44.725 158.9 43.125C150.2 38.125 139.1 41.025 134 49.725L91.6 121.825L50.0999 192.425C47.8999 195.425 46.7 199.125 46.7 202.825C46.8 212.825 54.9999 220.825 64.9999 220.725H234.6V220.625C237.8 220.625 240.9 219.825 243.7 218.225C252.2 213.525 255.3 202.725 250.6 194.225C250.5 194.125 250.5 194.025 250.4 193.825L208.6 122.625C208.3 122.325 208.1 122.025 208 121.725Z'
          fill='#FED4A2'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M214.7 220.725C178.3 255.225 121.2 255.225 84.8 220.725H214.7Z'
          fill='#FF945F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M91.5999 121.725L59.1 176.925C57.3 169.725 56.3999 162.325 56.4999 154.925C56.4999 113.225 84.8999 77.925 123.8 66.925L91.5999 121.725Z'
          fill='#FF945F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M240.3 176.825L208.4 122.625L207.9 121.625L175.7 66.925C214.5 78.025 242.9 113.225 242.9 154.925C243 162.325 242.1 169.725 240.3 176.825Z'
          fill='#FF945F'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M208.5 122.725L240.3 176.925C236.1 193.725 227.2 208.925 214.7 220.825H84.8C72.3 208.925 63.4 193.825 59.1 177.025L91.5 121.925L123.7 67.225C140.7 62.425 158.7 62.425 175.7 67.225L207.9 121.925L208.5 122.725Z'
          fill='white'
        />
        <path
          d='M168.6 183.325C167.9 183.325 167.2 183.125 166.6 182.825L150.2 174.225L133.8 182.825C132.3 183.625 130.6 183.425 129.2 182.525C127.9 181.525 127.2 179.925 127.5 178.225L130.6 159.925L117.3 147.025C116.1 145.825 115.7 144.125 116.2 142.525C116.7 140.925 118.1 139.825 119.7 139.525L138.1 136.825L146.3 120.225C147 118.725 148.5 117.825 150.2 117.825C151.9 117.825 153.4 118.725 154.1 120.225L162.3 136.825L180.7 139.525C182.4 139.725 183.7 140.925 184.2 142.525C184.7 144.125 184.3 145.825 183.1 147.025L169.8 159.925L172.9 178.225C173.2 179.825 172.5 181.525 171.2 182.525C170.4 183.125 169.5 183.325 168.6 183.325ZM150.2 168.525C150.9 168.525 151.6 168.725 152.2 169.025L167 176.825L164.2 160.325C164 158.925 164.4 157.525 165.5 156.525L177.5 144.825L160.9 142.425C159.5 142.225 158.3 141.325 157.6 140.025L150.2 125.025L142.8 140.025C142.2 141.325 140.9 142.225 139.5 142.425L122.9 144.825L134.9 156.525C135.9 157.525 136.4 158.925 136.2 160.325L133.4 176.825L148.2 169.025C148.8 168.725 149.5 168.525 150.2 168.525Z'
          fill='#FF5C3D'
        />
        <path
          d='M150.2 203.525C121.7 203.525 98.6 180.425 98.6 151.925C98.6 123.425 121.7 100.325 150.2 100.325C178.7 100.325 201.8 123.425 201.8 151.925C201.8 180.425 178.6 203.525 150.2 203.525ZM150.2 106.925C125.4 106.925 105.2 127.125 105.2 151.925C105.2 176.725 125.4 196.925 150.2 196.925C175 196.925 195.2 176.725 195.2 151.925C195.2 127.125 175 106.925 150.2 106.925Z'
          fill='#FF5C3D'
        />
      </svg>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppIcon);
