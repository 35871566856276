import React from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import routes from './routes';
import { Provider } from 'react-redux';
import { createAtreidesTheme, AtreidesThemeProvider, colors } from 'altreidsds';

import 'typeface-roboto';

const theme = createAtreidesTheme({
  overrides: {
    MuiInput: {
      // todo this breaks multiline input fields
      input: {
        fontSize: 16,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#b0b8c0',
      },
      colorPrimary: {
        fill: '#ffffff',
      },
      colorSecondary: {
        fill: '#53585c',
      },
    },
    MuiMenuItem: {
      root: {
        textTransform: 'uppercase',
        fontSize: '0.75rem',
      },
    },
    MuiSwitchBase: {
      root: {
        width: '80px',
        fontFamily: '"Montserrat", sans-serif',
        '& > span:first-child': {
          justifyContent: 'flex-start',
          transition: 'justify-content 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          position: 'relative',
          '&:before': {
            left: '30px',
            right: 'auto',
            top: '5px',
            height: '100%',
            position: 'absolute',
            color: '#ffffff',
            content: "'выкл'",
            fontSize: 12,
            textTransform: 'uppercase',
            textAlign: 'right',
          },
        },
      },
      checked: {
        '& > span:first-child': {
          justifyContent: 'flex-end',
          '&:before': {
            left: '22px',
            content: "'вкл'",
            textAlign: 'left',
          },
        },
      },
    },
    MuiSwitch: {
      root: {
        width: '80px',
      },
      bar: {
        height: 24,
        borderRadius: 12,
        marginTop: '-12px',
        left: '22%',
        width: '79px',
        opacity: 1,
        backgroundColor: 'rgba(83, 88, 92, 0.6)',
      },
      icon: {
        boxShadow: 'none',
        height: 24,
        width: 24,
        border: 'solid 1px rgba(83, 88, 92, 0.6)',
      },
      iconChecked: {
        boxShadow: 'none',
        borderColor: '#01a961',
      },
      checked: {
        transform: 'none',
        '& + $bar': {
          opacity: 1,
          backgroundColor: '#01a961',
        },
        '&$colorPrimary': {
          color: '#ffffff',
          '& + $bar': {
            opacity: 1,
            backgroundColor: '#01a961',
          },
        },
        '&$disabled': {
          opacity: 1,
          '& $icon': {
            boxShadow: 'none',
            background: '#ffffff',
            borderColor: '#01a961 !important',
          },
          '& $iconChecked': {
            background: '#ffffff',
            borderColor: 'rgba(1, 169, 97, 0.3) !important',
          },
          '& + $bar': {
            backgroundColor: '#01a961 !important',
            opacity: '0.3 !important',
          },
        },
      },
      colorPrimary: {
        '&$checked': {
          color: '#ffffff',
          '& + $bar': {
            opacity: 1,
            backgroundColor: '#01a961',
          },
        },
      },
      colorSecondary: {
        '& $iconChecked': {
          borderColor: '#EF2D56',
          background: '#ffffff',
        },
      },
      disabled: {
        '& $icon': {
          boxShadow: 'none',
          background: '#ffffff',
          borderColor: 'rgba(83, 88, 92, 0.3) !important',
        },
        '& $iconChecked': {
          background: '#ffffff',
          borderColor: 'rgba(83, 88, 92, 0.3) !important',
        },
        '& + $bar': {
          backgroundColor: 'rgba(83, 88, 92, 0.3) !important',
          opacity: 1,
        },
      },
    },
    MuiCheckbox: {
      root: {
        width: '24px',
        height: '24px',
        padding: 0,
        '& > span:first-child': {
          '&:before': {
            display: 'none',
          },
        },
      },
      colorPrimary: {
        '&$checked': {
          '& svg': {
            fill: '#227FC1',
          },
        },
      },
      checked: {
        '& > span:first-child': {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
  },
  palette: {
    black: {
      ...colors.black,
    },
    blue: {
      ...colors.aimylogic,
    },
    gray: {
      main: '#2a2722',
      blackTwo: '#2a2722',
      light: '#53585c',
      bc: '#bcbcbc',
      lightest: '#d9d9d9',
      black: '#000',
    },
    accent: {
      main: '#00a2ad',
      success: '#01a961',
      warning: '#f6a801',
      danger: '#fa5050',

      paleGold: '#fed766',
      coral: '#ef2d56',
    },
    primary: {
      main: '#227FC1',
      ...colors.aimylogic,
    },
    contrast: {
      main: '#53585c',
      light: '#d6dde4',
      lightest: '#e4e9ee',
      white: '#fff',
    },
    text: {
      white: '#fff',
      complete: colors.success.main,
    },
    social: {
      vk: '#4A76A8',
      google: '#3367D6',
      facebook: '#3b5998',
      telegram: '#1f96d4',
      viber: '#59267c',
      viberPublic: '#59267c',
      chatwidget: '#25d366',
      chatapi: '#25d366',
      resterisk: '#f68f1f',
      wechat: '#A7DF2B',
      sms: '#b00b00',
    },
    background: {
      paper: '#fff',
      default: '#eae9e9',
      visualEditor: '#f6f8fa',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: '"Montserrat", sans-serif',
    sidebar: {
      fontSize: 14,
      smallFontSize: 12,
    },
    headline: {
      color: '#000000',
      fontWeight: 600,
    },
    subheading: {
      color: '#000000',
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
    caption: {
      color: '#53585c',
      fontSize: '0.75rem',
      fontWeight: 300,
      textTransform: 'uppercase',
    },
    title: {
      color: '#798086',
    },
    display3: {
      color: '#000000',
      fontWeight: 600,
      fontSize: '1rem',
    },
    display2: {
      fontSize: '0.75rem',
    },
    display4: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    button: {
      fontSize: '0.75rem',
    },
  },
  bar: {},
});

ReactDOM.render(
  <Provider store={store}>
    <AtreidesThemeProvider theme={theme}>{routes}</AtreidesThemeProvider>
  </Provider>,

  document.getElementById('root')
);
