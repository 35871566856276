import React from 'react';
import { SvgIcon } from 'altreidsds';

export const ChannelsIcon = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.ChannelIcon'>
      <path d='M17.5,19 C16.1125,19 15,17.875 15,16.5 C15,15.1125 16.1125,14 17.5,14 C18.8807119,14 20,15.1192881 20,16.5 C20,17.8807119 18.8807119,19 17.5,19 Z M17.5,12 C15.0147186,12 13,14.0147186 13,16.5 C13,18.9852814 15.0147186,21 17.5,21 C19.9852814,21 22,18.9852814 22,16.5 C22,14.0147186 19.9852814,12 17.5,12 Z M6.5,19 C5.1125,19 4,17.875 4,16.5 C4,15.1125 5.1125,14 6.5,14 C7.88071187,14 9,15.1192881 9,16.5 C9,17.8807119 7.88071187,19 6.5,19 Z M6.5,12 C4.01471863,12 2,14.0147186 2,16.5 C2,18.9852814 4.01471863,21 6.5,21 C8.98528137,21 11,18.9852814 11,16.5 C11,14.0147186 8.98528137,12 6.5,12 Z M12,4 C13.3807119,4 14.5,5.11928813 14.5,6.5 C14.5,7.88071187 13.3807119,9 12,9 C10.6125,9 9.5,7.875 9.5,6.5 C9.5,5.1125 10.6125,4 12,4 Z M12,11 C14.4852814,11 16.5,8.98528137 16.5,6.5 C16.5,4.01471863 14.4852814,2 12,2 C9.51471863,2 7.5,4.01471863 7.5,6.5 C7.5,8.98528137 9.51471863,11 12,11 Z' />
    </SvgIcon>
  );
};

export const DataStorage = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.DataStorageIcon'>
      <path d='M11.8888889,2 C6.97777778,2 3,3.98888889 3,6.44444444 C3,8.9 6.97777778,10.8888889 11.8888889,10.8888889 C16.8,10.8888889 20.7777778,8.9 20.7777778,6.44444444 C20.7777778,3.98888889 16.8,2 11.8888889,2 Z M3,8.66666667 L3,12 C3,14.4555556 6.97777778,16.4444444 11.8888889,16.4444444 C16.8,16.4444444 20.7777778,14.4555556 20.7777778,12 L20.7777778,8.66666667 C20.7777778,11.1222222 16.8,13.1111111 11.8888889,13.1111111 C6.97777778,13.1111111 3,11.1222222 3,8.66666667 Z M3,14.2222222 L3,17.5555556 C3,20.0111111 6.97777778,22 11.8888889,22 C16.8,22 20.7777778,20.0111111 20.7777778,17.5555556 L20.7777778,14.2222222 C20.7777778,16.6777778 16.8,18.6666667 11.8888889,18.6666667 C6.97777778,18.6666667 3,16.6777778 3,14.2222222 Z' />
    </SvgIcon>
  );
};

export const Libraries = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.LibariesIcon'>
      <path d='M18.3636364,3 L13.8181818,7.09090909 L13.8181818,17.0909091 L18.3636364,13 L18.3636364,3 Z M7,5.72727273 C5.22727273,5.72727273 3.31818182,6.09090909 2,7.09090909 L2,20.4181818 C2,20.6454545 2.22727273,20.8727273 2.45454545,20.8727273 C2.54545455,20.8727273 2.59090909,20.8090909 2.68181818,20.8090909 C3.90909091,20.2181818 5.68181818,19.8181818 7,19.8181818 C8.77272727,19.8181818 10.6818182,20.1818182 12,21.1818182 C13.2272727,20.4090909 15.4545455,19.8181818 17,19.8181818 C18.5,19.8181818 20.0454545,20.1 21.3181818,20.7818182 C21.4090909,20.8272727 21.4545455,20.8090909 21.5454545,20.8090909 C21.7727273,20.8090909 22,20.5818182 22,20.3545455 L22,7.09090909 C21.4545455,6.68181818 20.8636364,6.40909091 20.1818182,6.18181818 L20.1818182,8 L20.1818182,13 L20.1818182,18.4545455 C19.1818182,18.1363636 18.0909091,18 17,18 C15.4545455,18 13.2272727,18.5909091 12,19.3636364 L12,13 L12,8.90909091 L12,7.09090909 C10.6818182,6.09090909 8.77272727,5.72727273 7,5.72727273 Z' />
    </SvgIcon>
  );
};

export const Support = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.SupportIcon'>
      <path d='M19.79,15.41 C20.74,13.24 20.74,10.75 19.79,8.59 L17.05,9.83 C17.65,11.21 17.65,12.78 17.06,14.17 L19.79,15.41 Z M15.42,4.21 C13.25,3.26 10.76,3.26 8.59,4.21 L9.83,6.94 C11.22,6.35 12.79,6.35 14.18,6.95 L15.42,4.21 Z M4.21,8.58 C3.26,10.76 3.26,13.24 4.21,15.42 L6.95,14.17 C6.35,12.79 6.35,11.21 6.95,9.82 L4.21,8.58 Z M8.59,19.79 C10.76,20.74 13.25,20.74 15.42,19.78 L14.18,17.05 C12.8,17.65 11.22,17.65 9.84,17.06 L8.59,19.79 Z M12,2 C14.6521649,2 17.195704,3.0535684 19.0710678,4.92893219 C20.9464316,6.80429597 22,9.3478351 22,12 C22,17.5228475 17.5228475,22 12,22 C9.3478351,22 6.80429597,20.9464316 4.92893219,19.0710678 C3.0535684,17.195704 2,14.6521649 2,12 C2,9.3478351 3.0535684,6.80429597 4.92893219,4.92893219 C6.80429597,3.0535684 9.3478351,2 12,2 Z M12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 Z' />
    </SvgIcon>
  );
};
export const Logout = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.LogoutIcon'>
      <path d='M16.8148148,17.25 L16.8148148,14 L9.90123457,14 L9.90123457,10 L16.8148148,10 L16.8148148,6.75 L22,12 L16.8148148,17.25 Z M12.8641975,2 C13.9551304,2 14.8395062,2.8954305 14.8395062,4 L14.8395062,8 L12.8641975,8 L12.8641975,4 L3.97530864,4 L3.97530864,20 L12.8641975,20 L12.8641975,16 L14.8395062,16 L14.8395062,20 C14.8395062,21.1045695 13.9551304,22 12.8641975,22 L3.97530864,22 C2.8843758,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8843758,2 3.97530864,2 L12.8641975,2 Z' />
    </SvgIcon>
  );
};

export const Keys = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.KeysIcon'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z' />
    </SvgIcon>
  );
};

export const Vendors = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.VendorsIcon'>
      <path d='M0 0h24v24H0z' fill='none' />
      <path d='M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z' />
    </SvgIcon>
  );
};

export const Revoke = props => {
  return (
    <SvgIcon {...props} data-test-id='Sidebar.RevokeIcon'>
      <path d='M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z' />
    </SvgIcon>
  );
};
