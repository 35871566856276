import {
  ADD_CLIENT_FULFILLED,
  ADD_CLIENT_PENDING,
  ADD_CLIENT_REJECTED,
  ADD_KEY_REJECTED,
  ADD_KEY_FULFILLED,
  ADD_KEY_PENDING,
  ADD_VENDOR_FULFILLED,
  ADD_VENDOR_PENDING,
  ADD_VENDOR_REJECTED,
  BLOCK_CLIENT_FULFILLED,
  DELETE_VENDOR_FULFILLED,
  DELETE_VENDOR_PENDING,
  DELETE_VENDOR_REJECTED,
  EDIT_VENDOR_FULFILLED,
  EDIT_VENDOR_PENDING,
  EDIT_VENDOR_REJECTED,
  GET_CLIENT_INFO_FULFILLED,
  GET_CLIENT_INFO_PENDING,
  GET_CLIENT_INFO_REJECTED,
  GET_CLIENTS_LIST_FULFILLED,
  GET_CLIENTS_LIST_PENDING,
  GET_CLIENTS_LIST_REJECTED,
  GET_KEY_GRAPH_FULFILLED,
  GET_KEY_GRAPH_PENDING,
  GET_KEY_GRAPH_REJECTED,
  UNLOCK_CLIENT_FULFILLED,
  UPDATE_CLIENT_FULFILLED,
  GET_EXPORT_PERIOD_PENDING,
  GET_EXPORT_PERIOD_REJECTED,
  GET_EXPORT_PERIOD_FULFILLED,
  EXPORT_STATISTICS_PENDING,
  EXPORT_STATISTICS_FULFILLED,
  EXPORT_STATISTICS_REJECTED,
  PREIMPORT_STATISTICS_PENDING,
  PREIMPORT_STATISTICS_FULFILLED,
  PREIMPORT_STATISTICS_REJECTED,
  IMPORT_KEY_PENDING,
  IMPORT_KEY_FULFILLED,
  IMPORT_KEY_REJECTED,
  EXPORT_KEY_PENDING,
  EXPORT_KEY_FULFILLED,
  EXPORT_KEY_REJECTED,
} from '../../constants/clients.actions';

const InitialState = {
  tariffData: {},
  emails: [],
  usedLimitsGraphPoints: {},
  fetching: false,
  name: '',
  formFetching: false,
  graphFetching: false,
  consumerBlocked: false,
  platformActive: false,
  clientsList: [],
  vendorFetching: false,
  addKeyFetching: false,
  importFetching: false,
  phone: '',
};

export default function ClientsReducer(state = InitialState, action) {
  switch (action.type) {
    case GET_CLIENT_INFO_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_CLIENT_INFO_FULFILLED: {
      return {
        ...state,
        tariffData: action.payload.data.tariffData,
        emails: action.payload.data.emails,
        phone: action.payload.data.phone,
        usedLimitsGraphPoints: action.payload.data.usedLimitsGraphPoints,
        lastConnectionAt: action.payload.data.lastConnectionAt,
        name: action.payload.data.name,
        platformActive: action.payload.data.isPlatformActive,
        consumerBlocked: action.payload.data.isConsumerBlocked,
        uuid: action.payload.data.uuid,
        fetching: false,
      };
    }

    case GET_CLIENT_INFO_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case ADD_CLIENT_PENDING: {
      return {
        ...state,
        formFetching: true,
      };
    }

    case ADD_CLIENT_REJECTED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case ADD_CLIENT_FULFILLED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case GET_CLIENTS_LIST_PENDING: {
      return {
        ...state,
        fetching: true,
      };
    }

    case GET_CLIENTS_LIST_REJECTED: {
      return {
        ...state,
        fetching: false,
      };
    }

    case GET_CLIENTS_LIST_FULFILLED: {
      return {
        ...state,
        clientsList: action.payload.data,
        fetching: false,
      };
    }

    case UPDATE_CLIENT_FULFILLED: {
      return {
        ...state,
        emails: action.payload.data.emails,
        phone: action.payload.data.phone,
        name: action.payload.data.name,
      };
    }

    case ADD_KEY_PENDING: {
      return {
        ...state,
        addKeyFetching: true,
      };
    }

    case ADD_KEY_REJECTED: {
      return {
        ...state,
        addKeyFetching: false,
      };
    }

    case ADD_KEY_FULFILLED: {
      return {
        ...state,
        addKeyFetching: false,
      };
    }

    case GET_KEY_GRAPH_PENDING: {
      return {
        ...state,
        graphFetching: true,
      };
    }

    case GET_KEY_GRAPH_FULFILLED: {
      return {
        ...state,
        usedLimitsGraphPoints: action.payload.data,
        graphFetching: false,
      };
    }

    case GET_KEY_GRAPH_REJECTED: {
      return {
        ...state,
        graphFetching: false,
      };
    }

    case BLOCK_CLIENT_FULFILLED: {
      return {
        ...state,
        consumerBlocked: true,
      };
    }

    case UNLOCK_CLIENT_FULFILLED: {
      return {
        ...state,
        consumerBlocked: false,
      };
    }

    case ADD_VENDOR_PENDING: {
      return {
        ...state,
        vendorFetching: true,
      };
    }

    case ADD_VENDOR_REJECTED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case ADD_VENDOR_FULFILLED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case DELETE_VENDOR_PENDING: {
      return {
        ...state,
        vendorFetching: true,
      };
    }

    case DELETE_VENDOR_REJECTED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case DELETE_VENDOR_FULFILLED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case EDIT_VENDOR_PENDING: {
      return {
        ...state,
        vendorFetching: true,
      };
    }

    case EDIT_VENDOR_REJECTED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case EDIT_VENDOR_FULFILLED: {
      return {
        ...state,
        vendorFetching: false,
      };
    }

    case IMPORT_KEY_PENDING: {
      return {
        ...state,
        formFetching: true,
      };
    }

    case IMPORT_KEY_FULFILLED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case IMPORT_KEY_REJECTED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case EXPORT_KEY_PENDING: {
      return {
        ...state,
        formFetching: true,
      };
    }

    case EXPORT_KEY_FULFILLED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case EXPORT_KEY_REJECTED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case GET_EXPORT_PERIOD_PENDING: {
      return {
        ...state,
        formFetching: true,
      };
    }

    case GET_EXPORT_PERIOD_FULFILLED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case GET_EXPORT_PERIOD_REJECTED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case EXPORT_STATISTICS_PENDING: {
      return {
        ...state,
        formFetching: true,
      };
    }

    case EXPORT_STATISTICS_FULFILLED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case EXPORT_STATISTICS_REJECTED: {
      return {
        ...state,
        formFetching: false,
      };
    }

    case PREIMPORT_STATISTICS_PENDING: {
      return {
        ...state,
        importFetching: true,
      };
    }

    case PREIMPORT_STATISTICS_FULFILLED: {
      return {
        ...state,
        importFetching: false,
      };
    }

    case PREIMPORT_STATISTICS_REJECTED: {
      return {
        ...state,
        importFetching: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}
