import React, { Component } from 'react';
// import { ListItem, ListItemIcon, ListItemText } from 'altreidsds';
import { ListItem, ListItemText, ListItemIcon } from 'altreidsds';
import { NavLink } from 'react-router-dom';
import localize from '../../../localization';

import {} from 'altreidsds/dist/icons';
import { Vendors } from './SidebarIcons';

class BotNavListItem extends Component {
  render() {
    const { listIconClass, textClass, listItemClass, textListItem } = this.props;

    return (
      <ListItem data-test-id='BotNavListItem.listItem' button className={listItemClass} key='clients'>
        <NavLink to='/clients' className={textClass} activeClassName='active'>
          <ListItemIcon className={listIconClass}>
            <Vendors />
          </ListItemIcon>
          <ListItemText className={textListItem} disableTypography primary={localize.translate('Clients')} />
        </NavLink>
      </ListItem>
    );
  }
}

export default BotNavListItem;
