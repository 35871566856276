import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'altreidsds';
// import localize from "../../localization";

import { AppBar, Toolbar, AtreidesIcon } from 'altreidsds';
import { withRouter } from 'react-router-dom';

import localize from '../../localization';
import { Link } from 'react-router-dom';

const drawerWidth = 260;
const drawerPaperCloseWidth = 80;

const styles = theme => ({
  appBarRoot: {
    minHeight: 52,
  },
  headerBoundPlace: {
    flex: '1 0 auto',
    textAlign: 'left',
  },
  popperClose: {
    pointerEvents: 'none',
  },
  collapseRoot: {
    maxHeight: 'calc( 100vh - 80px )',
    overflowY: 'auto',
  },
  menuToggler: {
    border: 'none',
    fontWeight: 600,
    fontSize: 16,
    textTransform: 'inherit',
    padding: '7px 20px 8px',
  },
  flexCenter: {
    alignItems: 'center',
  },
  headerBotSelector: {
    cursor: 'pointer',
    padding: '8px 40px',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.5,
    '&:hover': {
      backgroundColor: 'rgba(217, 217, 217, 0.2);',
    },
  },
  appBar: {
    boxShadow: 'none',
    position: 'absolute',
    zIndex: theme.zIndex.drawer - 1,
    marginLeft: drawerPaperCloseWidth,
    width: `calc(100% - ${drawerPaperCloseWidth}px)`,
    background: '#fff',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  newBotButton: {
    padding: 0,
    border: 'none',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontWeight: 600,
    '& .plus-icon': {
      marginRight: '6px',
    },
  },
  docsButton: {
    padding: '0 13px',
  },
  newBotButtonCollapse: {
    fontSize: 12,
  },
  toolbar: {
    padding: '0 1em',
    minHeight: '52px',
  },
  headerSearch: {
    flex: '1 1 auto',
    textAlign: 'right',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  backLink: {
    textDecoration: 'none',
    marginLeft: 10,
    '& svg path': {
      fill: '#7AB2DA',
    },
  },
});

class Header extends Component {
  state = {
    showCreateNewsletterModal: false,
    searchPanelActive: false,
    openMenu: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        openMenu: false,
      });
    }
  }

  render() {
    const { classes, open } = this.props;
    const clientId = this.props.location.pathname.split('/')[2];

    return (
      <AppBar key='app_header' color='default' className={classNames(classes.appBar, open && classes.appBarShift)}>
        <Toolbar data-test-id='Header.toolbar' disableGutters={!open} classes={{ root: classes.toolbar }}>
          <div id='headerBoundPlace' className={classes.headerBoundPlace} data-test-id='Header.clientId'>
            {Boolean(clientId) ? (
              <Link to='/clients' className={classes.backLink}>
                <AtreidesIcon name='back' data-test-id='Header.backToClientsLink' />{' '}
                {localize.translate('Back to clients')}
              </Link>
            ) : null}
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Header));
