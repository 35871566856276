export const GET_CLIENTS_LIST = 'GET_CLIENTS_LIST';
export const GET_CLIENTS_LIST_PENDING = 'GET_CLIENTS_LIST_PENDING';
export const GET_CLIENTS_LIST_FULFILLED = 'GET_CLIENTS_LIST_FULFILLED';
export const GET_CLIENTS_LIST_REJECTED = 'GET_CLIENTS_LIST_REJECTED';

export const GET_CLIENT_INFO = 'GET_CLIENT_INFO';
export const GET_CLIENT_INFO_PENDING = 'GET_CLIENT_INFO_PENDING';
export const GET_CLIENT_INFO_FULFILLED = 'GET_CLIENT_INFO_FULFILLED';
export const GET_CLIENT_INFO_REJECTED = 'GET_CLIENT_INFO';

export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_CLIENT_PENDING = 'ADD_CLIENT_PENDING';
export const ADD_CLIENT_FULFILLED = 'ADD_CLIENT_FULFILLED';
export const ADD_CLIENT_REJECTED = 'ADD_CLIENT_REJECTED';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_PENDING = 'UPDATE_CLIENT_PENDING';
export const UPDATE_CLIENT_FULFILLED = 'UPDATE_CLIENT_FULFILLED';
export const UPDATE_CLIENT_REJECTED = 'UPDATE_CLIENT_REJECTED';

export const GET_KEY_GRAPH = 'GET_KEY_GRAPH';
export const GET_KEY_GRAPH_PENDING = 'GET_KEY_GRAPH_PENDING';
export const GET_KEY_GRAPH_FULFILLED = 'GET_KEY_GRAPH_FULFILLED';
export const GET_KEY_GRAPH_REJECTED = 'GET_KEY_GRAPH_REJECTED';

export const BLOCK_CLIENT = 'BLOCK_CLIENT';
export const BLOCK_CLIENT_PENDING = 'BLOCK_CLIENT_PENDING';
export const BLOCK_CLIENT_FULFILLED = 'BLOCK_CLIENT_FULFILLED';
export const BLOCK_CLIENT_REJECTED = 'BLOCK_CLIENT_REJECTED';

export const UNLOCK_CLIENT = 'UNLOCK_CLIENT';
export const UNLOCK_CLIENT_PENDING = 'UNLOCK_CLIENT_PENDING';
export const UNLOCK_CLIENT_FULFILLED = 'UNLOCK_CLIENT_FULFILLED';
export const UNLOCK_CLIENT_REJECTED = 'UNLOCK_CLIENT_REJECTED';

export const ADD_VENDOR = 'ADD_VENDOR';
export const ADD_VENDOR_PENDING = 'ADD_VENDOR_PENDING';
export const ADD_VENDOR_FULFILLED = 'ADD_VENDOR_FULFILLED';
export const ADD_VENDOR_REJECTED = 'ADD_VENDOR_REJECTED';

export const EDIT_VENDOR = 'EDIT_VENDOR';
export const EDIT_VENDOR_PENDING = 'EDIT_VENDOR_PENDING';
export const EDIT_VENDOR_FULFILLED = 'EDIT_VENDOR_FULFILLED';
export const EDIT_VENDOR_REJECTED = 'EDIT_VENDOR_REJECTED';

export const DELETE_VENDOR = 'DELETE_VENDOR';
export const DELETE_VENDOR_PENDING = 'DELETE_VENDOR_PENDING';
export const DELETE_VENDOR_FULFILLED = 'DELETE_VENDOR_FULFILLED';
export const DELETE_VENDOR_REJECTED = 'DELETE_VENDOR_REJECTED';

export const EXPORT_KEY = 'EXPORT_KEY';
export const EXPORT_KEY_PENDING = 'EXPORT_KEY_PENDING';
export const EXPORT_KEY_FULFILLED = 'EXPORT_KEY_FULFILLED';
export const EXPORT_KEY_REJECTED = 'EXPORT_KEY_REJECTED';

export const ADD_KEY = 'ADD_KEY';
export const ADD_KEY_FULFILLED = 'ADD_KEY_FULFILLED';
export const ADD_KEY_PENDING = 'ADD_KEY_PENDING';
export const ADD_KEY_REJECTED = 'ADD_KEY_REJECTED';

export const VALIDATE_KEY = 'VALIDATE_KEY';
export const VALIDATE_KEY_FULFILLED = 'VALIDATE_KEY_FULFILLED';
export const VALIDATE_KEY_PENDING = 'VALIDATE_KEY_PENDING';
export const VALIDATE_KEY_REJECTED = 'VALIDATE_KEY_REJECTED';

export const IMPORT_KEY = 'IMPORT_KEY';
export const IMPORT_KEY_FULFILLED = 'IMPORT_KEY_FULFILLED';
export const IMPORT_KEY_PENDING = 'IMPORT_KEY_PENDING';
export const IMPORT_KEY_REJECTED = 'IMPORT_KEY_REJECTED';

export const GET_KEY_INFO = 'GET_KEY_INFO';
export const GET_KEY_INFO_FULFILLED = 'GET_KEY_INFO_FULFILLED';
export const GET_KEY_INFO_PENDING = 'GET_KEY_INFO_PENDING';
export const GET_KEY_INFO_REJECTED = 'GET_KEY_INFO_REJECTED';

export const GET_EXPORT_PERIOD = 'GET_EXPORT_PERIOD';
export const GET_EXPORT_PERIOD_FULFILLED = 'GET_EXPORT_PERIOD_FULFILLED';
export const GET_EXPORT_PERIOD_PENDING = 'GET_EXPORT_PERIOD_PENDING';
export const GET_EXPORT_PERIOD_REJECTED = 'GET_EXPORT_PERIOD_REJECTED';

export const EXPORT_STATISTICS = 'EXPORT_STATISTICS';
export const EXPORT_STATISTICS_FULFILLED = 'EXPORT_STATISTICS_FULFILLED';
export const EXPORT_STATISTICS_PENDING = 'EXPORT_STATISTICS_PENDING';
export const EXPORT_STATISTICS_REJECTED = 'EXPORT_STATISTICS_REJECTED';

export const IMPORT_STATISTICS = 'IMPORT_STATISTICS';
export const IMPORT_STATISTICS_FULFILLED = 'IMPORT_STATISTICS_FULFILLED';
export const IMPORT_STATISTICS_PENDING = 'IMPORT_STATISTICS_PENDING';
export const IMPORT_STATISTICS_REJECTED = 'IMPORT_STATISTICS_REJECTED';

export const PREIMPORT_STATISTICS = 'PREIMPORT_STATISTICS';
export const PREIMPORT_STATISTICS_FULFILLED = 'PREIMPORT_STATISTICS_FULFILLED';
export const PREIMPORT_STATISTICS_PENDING = 'PREIMPORT_STATISTICS_PENDING';
export const PREIMPORT_STATISTICS_REJECTED = 'PREIMPORT_STATISTICS_REJECTED';

export const CHECK_IMPORT_STATUS = 'CHECK_IMPORT_STATUS';
export const CHECK_IMPORT_STATUS_FULFILLED = 'CHECK_IMPORT_STATUS_FULFILLED';
export const CHECK_IMPORT_STATUS_PENDING = 'CHECK_IMPORT_STATUS_PENDING';
export const CHECK_IMPORT_STATUS_REJECTED = 'CHECK_IMPORT_STATUS_REJECTED';

export const GET_KEYS = 'GET_KEYS';
export const GET_KEYS_FULFILLED = 'GET_KEYS_FULFILLED';
export const GET_KEYS_PENDING = 'GET_KEYS_PENDING';
export const GET_KEYS_REJECTED = 'GET_KEYS_REJECTED';
