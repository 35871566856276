import store from './store';

export default function isAccess(assesList) {
  let flag = true;
  if (assesList.hasOwnProperty('length')) {
    for (let i = 0; i < assesList.length; i++) {
      flag = isAccessFunction(assesList[i]) || isEnabledFunction(assesList[i]);
      if (!flag) {
        return flag;
      }
    }
  }
  return flag;
}

function isAccessFunction(permissionName) {
  let state = store.getState();
  //TODO remove localstorage hack?
  if (permissionName === 'ALL') {
    //todo migrate this to serverside
    return true;
  }
  try {
    let allPermissions = state.CurrentUserReducer.allPermissions;
    if (allPermissions.length === 0) {
      allPermissions = JSON.parse(localStorage.CURRENT_USER).allPermissions;
    }

    let isAccess = false;
    if (!!state.AccountsReducer.loggedInAccount) {
      isAccess = allPermissions.findIndex(permission => permission.name === 'ACCOUNTS_LOGIN_READ') > -1;
    } else {
      isAccess = allPermissions.findIndex(permission => permission.name === permissionName) > -1;
    }
    return isAccess;
  } catch (e) {
    return false;
  }
}

function isEnabledFunction(featureName) {
  try {
    const state = { ...store.getState() };
    let allFeatures = [];
    if (isInternal()) {
      allFeatures = state.AccountsReducer.loggedInAccountFeatures;
      if (allFeatures.length === 0) {
        allFeatures = JSON.parse(localStorage.getItem('LOGIN_TO_ACCOUNT_FEATURES'));
      }
    } else {
      allFeatures = state.CurrentUserReducer.features;
      if (allFeatures.length === 0) {
        allFeatures = JSON.parse(localStorage.CURRENT_USER).features;
      }
    }
    let curFeature = allFeatures.filter(feature => {
      return feature.name === featureName;
    })[0];

    return !!curFeature && curFeature.enabled;
  } catch (e) {
    return false;
  }
}

export function isLoggedIn() {
  return Boolean(store.getState().CurrentUserReducer.currentUser);
}

export function isInternal() {
  // this check if the user is super Admin
  let internal = false;
  const state = { ...store.getState() };
  if (!state.CurrentUserReducer.currentUser) {
    try {
      internal = JSON.parse(localStorage.CURRENT_USER).internal;
    } catch (e) {
      internal = state.CurrentUserReducer.internal;
    }
  } else {
    internal = state.CurrentUserReducer.internal;
  }
  return internal;
}

export function getCurrentProject() {
  let localStorageCurProject = null;
  try {
    localStorageCurProject = localStorage.CURRENT_PROJECT_SHORT_NAME;
  } catch (e) {
    localStorageCurProject = null;
  }

  return store.getState().CurrentProjectsReducer.currentProject || localStorageCurProject;
}
